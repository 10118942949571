export class MenuConfig {
	public defaults: any = {
		header: {
			self: {},
			items: [
				{
					title: "Dashboards",
					root: true,
					alignment: "left",
					page: "/dashboard",
					translate: "MENU.DASHBOARD",
				},
				{
					title: "Components",
					root: true,
					alignment: "left",
					toggle: "click",
					submenu: [
						{
							title: "Google Material",
							bullet: "dot",
							icon: "flaticon-interface-7",
							submenu: [
								{
									title: "Form Controls",
									bullet: "dot",
									submenu: [
										{
											title: "Auto Complete",
											page: "/material/form-controls/autocomplete",
											permission:
												"accessToECommerceModule",
										},
										{
											title: "Checkbox",
											page: "/material/form-controls/checkbox",
										},
										{
											title: "Radio Button",
											page: "/material/form-controls/radiobutton",
										},
										{
											title: "Datepicker",
											page: "/material/form-controls/datepicker",
										},
										{
											title: "Form Field",
											page: "/material/form-controls/formfield",
										},
										{
											title: "Input",
											page: "/material/form-controls/input",
										},
										{
											title: "Select",
											page: "/material/form-controls/select",
										},
										{
											title: "Slider",
											page: "/material/form-controls/slider",
										},
										{
											title: "Slider Toggle",
											page: "/material/form-controls/slidertoggle",
										},
									],
								},
								{
									title: "Navigation",
									bullet: "dot",
									submenu: [
										{
											title: "Menu",
											page: "/material/navigation/menu",
										},
										{
											title: "Sidenav",
											page: "/material/navigation/sidenav",
										},
										{
											title: "Toolbar",
											page: "/material/navigation/toolbar",
										},
									],
								},
								{
									title: "Layout",
									bullet: "dot",
									submenu: [
										{
											title: "Card",
											page: "/material/layout/card",
										},
										{
											title: "Divider",
											page: "/material/layout/divider",
										},
										{
											title: "Expansion panel",
											page: "/material/layout/expansion-panel",
										},
										{
											title: "Grid list",
											page: "/material/layout/grid-list",
										},
										{
											title: "List",
											page: "/material/layout/list",
										},
										{
											title: "Tabs",
											page: "/material/layout/tabs",
										},
										{
											title: "Stepper",
											page: "/material/layout/stepper",
										},
										{
											title: "Default Forms",
											page: "/material/layout/default-forms",
										},
										{
											title: "Tree",
											page: "/material/layout/tree",
										},
									],
								},
								{
									title: "Buttons & Indicators",
									bullet: "dot",
									submenu: [
										{
											title: "Button",
											page: "/material/buttons-and-indicators/button",
										},
										{
											title: "Button toggle",
											page: "/material/buttons-and-indicators/button-toggle",
										},
										{
											title: "Chips",
											page: "/material/buttons-and-indicators/chips",
										},
										{
											title: "Icon",
											page: "/material/buttons-and-indicators/icon",
										},
										{
											title: "Progress bar",
											page: "/material/buttons-and-indicators/progress-bar",
										},
										{
											title: "Progress spinner",
											page: "/material/buttons-and-indicators/progress-spinner",
										},
										{
											title: "Ripples",
											page: "/material/buttons-and-indicators/ripples",
										},
									],
								},
								{
									title: "Popups & Modals",
									bullet: "dot",
									submenu: [
										{
											title: "Bottom sheet",
											page: "/material/popups-and-modals/bottom-sheet",
										},
										{
											title: "Dialog",
											page: "/material/popups-and-modals/dialog",
										},
										{
											title: "Snackbar",
											page: "/material/popups-and-modals/snackbar",
										},
										{
											title: "Tooltip",
											page: "/material/popups-and-modals/tooltip",
										},
									],
								},
								{
									title: "Data table",
									bullet: "dot",
									submenu: [
										{
											title: "Paginator",
											page: "/material/data-table/paginator",
										},
										{
											title: "Sort header",
											page: "/material/data-table/sort-header",
										},
										{
											title: "Table",
											page: "/material/data-table/table",
										},
									],
								},
							],
						},
						{
							title: "Ng-Bootstrap",
							bullet: "dot",
							icon: "flaticon-web",
							submenu: [
								{
									title: "Accordion",
									page: "/ngbootstrap/accordion",
								},
								{
									title: "Alert",
									page: "/ngbootstrap/alert",
								},
								{
									title: "Buttons",
									page: "/ngbootstrap/buttons",
								},
								{
									title: "Carousel",
									page: "/ngbootstrap/carousel",
								},
								{
									title: "Collapse",
									page: "/ngbootstrap/collapse",
								},
								{
									title: "Datepicker",
									page: "/ngbootstrap/datepicker",
								},
								{
									title: "Dropdown",
									page: "/ngbootstrap/dropdown",
								},
								{
									title: "Modal",
									page: "/ngbootstrap/modal",
								},
								{
									title: "Pagination",
									page: "/ngbootstrap/pagination",
								},
								{
									title: "Popover",
									page: "/ngbootstrap/popover",
								},
								{
									title: "Progressbar",
									page: "/ngbootstrap/progressbar",
								},
								{
									title: "Rating",
									page: "/ngbootstrap/rating",
								},
								{
									title: "Tabs",
									page: "/ngbootstrap/tabs",
								},
								{
									title: "Timepicker",
									page: "/ngbootstrap/timepicker",
								},
								{
									title: "Tooltips",
									page: "/ngbootstrap/tooltip",
								},
								{
									title: "Typehead",
									page: "/ngbootstrap/typehead",
								},
							],
						},
					],
				},
				{
					title: "Applications",
					root: true,
					alignment: "left",
					toggle: "click",
					submenu: [
						{
							title: "eCommerce",
							bullet: "dot",
							icon: "flaticon-business",
							permission: "accessToECommerceModule",
							submenu: [
								{
									title: "Customers",
									page: "/ecommerce/customers",
								},
								{
									title: "Products",
									page: "/ecommerce/products",
								},
							],
						},
						{
							title: "User Management",
							bullet: "dot",
							icon: "flaticon-user",
							submenu: [
								{
									title: "Users",
									page: "/user-management/users",
								},
								{
									title: "Roles",
									page: "/user-management/roles",
								},
							],
						},
					],
				},
				{
					title: "Custom",
					root: true,
					alignment: "left",
					toggle: "click",
					submenu: [
						{
							title: "Error Pages",
							bullet: "dot",
							icon: "flaticon2-list-2",
							submenu: [
								{
									title: "Error 1",
									page: "/error/error-v1",
								},
								{
									title: "Error 2",
									page: "/error/error-v2",
								},
								{
									title: "Error 3",
									page: "/error/error-v3",
								},
								{
									title: "Error 4",
									page: "/error/error-v4",
								},
								{
									title: "Error 5",
									page: "/error/error-v5",
								},
								{
									title: "Error 6",
									page: "/error/error-v6",
								},
							],
						},
						{
							title: "Wizard",
							bullet: "dot",
							icon: "flaticon2-mail-1",
							submenu: [
								{
									title: "Wizard 1",
									page: "/wizard/wizard-1",
								},
								{
									title: "Wizard 2",
									page: "/wizard/wizard-2",
								},
								{
									title: "Wizard 3",
									page: "/wizard/wizard-3",
								},
								{
									title: "Wizard 4",
									page: "/wizard/wizard-4",
								},
							],
						},
					],
				},
			],
		},
		aside: {
			self: {},
			items: [
				{
					title: "Dashboard",
					root: true,
					icon: "flaticon2-architecture-and-city",
					page: "/admin/dashboard",
					translate: "MENU.DASHBOARD",
					bullet: "dot",
					role: "admin",
				},
				{
					title: "Customers",
					root: true,
					icon: "flaticon2-user",
					page: "/admin/customers",
					role: "admin",
				},
				{ section: "Components" },
				{
					title: "Payments",
					bullet: "dot",
					icon: "fa fa-money-check-alt",
					root: true,
					role: "admin",
					submenu: [
						// {
						// 	title: 'Taxes/Surcharges',
						// 	page: '/admin/payment/taxes-surcharges',
						// 	role: 'admin',
						// },
						// {
						// 	title: "Taxes/Surcharges",
						// 	page: "/admin/payment/taxes-surcharges",
						// 	role: "admin",
						// },
						{
							title: "Add Charge",
							page: "/admin/payment/addcharges",
							role: "admin",
						},
						{
							title: "Card Payment",
							page: "/admin/payment/cardpayment",
							role: "admin",
						},
						{
							title: "Charges",
							page: "/admin/payment/charges",
							role: "admin",
						},
						{
							title: "Credit Cards",
							page: "/admin/payment/creditcard",
							role: "admin",
						},

						{
							title: "History",
							page: "/admin/payment/history",
							role: "admin",
						},
						{
							title: "Manual Payment",
							page: "/admin/payment/manualpayment",
							role: "admin",
						},
					],
				},
				{ section: "Components" },
				{
					title: "Whitelist",
					bullet: "dot",
					icon: "flaticon-download-1",
					root: true,
					role: "admin",
					submenu: [
						{
							title: "Whitelist Ip",
							page: "/admin/device-setup/whitelist-ip",
							role: "admin",
						},
						{
							title: "Ip History",
							page: "/admin/device-setup/whitelistedip-history",
							role: "admin",
						},
						{
							title: "Whitelist Url",
							page: "/admin/device-setup/whitelist-url",
							role: "admin",
						},
						{
							title: "Url History",
							page: "/admin/device-setup/whitelistedurl-history",
							role: "admin",
						},
					],
				},

				{
					title: "Email Template",
					root: true,
					icon: "flaticon2-email",
					page: "/admin/emailtemplate",
					role: "admin",
				},
				{ section: "Components" },
				{
					title: "DIDs",
					bullet: "dot",
					icon: "flaticon2-list",
					notification:"flaticon2-bell-4",
					root: true,
					role: "admin",
					badge: {
						value: "",
						type: "",
					},
					submenu: [
						{
							title: "Manage",
							page: "/admin/did/inbound-did",
							role: "admin",
						},
						{
							title: "Order History",
							page: "/admin/did/order-history",
							role: "admin",
							badge: {
								value: "",
								type: "",
							},
						},
						{
							title: "Inventory",
							page: "/admin/did/inventory",
							role: "admin",
							submenu: [
								{
									title: "Manage",
									page: "/admin/did/manage",
									role: "admin",
								},
								{
									title: "Batch Operations",
									page: "/admin/did/batch-operations",
									role: "admin",
								},
								{
									title: "Import",
									page: "/admin/did/import",
									role: "admin",
								},
							],
						},
						{
							title: "Bulk Tools",
							page: "/admin/did/import-did",
							role: "admin",
							submenu: [
								{
									title: "Import",
									page: "/admin/did/import-did",
									role: "admin",
								},
								{
									title: "Disconnect",
									page: "/admin/did/bulk-disconnect",
									role: "admin",
								},
								{
									title: "Purchase",
									page: "/admin/did/bulk-did-purchase",
									role: "admin",
								},
								{
									title: "Destination Update",
									page: "/admin/did/bulk-destination-update",
									role: "admin",
								},
							],
						},

					],
				},
				{
					title: "Notifications",
					root: true,
					icon: "flaticon2-sms",
					page: "/admin/global-notifications",
					role: "admin",
				},
				{
					title: "Subscriptions",
					root: true,
					icon: "flaticon-envelope",
					page: "/admin/subscriptions",
					role: "admin",
				},
				// {
				// 	title: "Subscriptions",
				// 	root: true,
				// 	icon: "flaticon-envelope",
				// 	page: "/admin/subscriptions",
				// 	role: "admin",
				// },

				{
					title: "Settings",
					root: true,
					icon: "flaticon2-settings",

					role: "admin",
					submenu: [
						{
							title: "DIDs",
							page: "/admin/settings/dids",
							role: "admin",
							badge: {
								value: "",
								type: "",
							},
						},
						{
							title: "Caller Reputation",
							page: "/admin/settings/caller-reputation",
							role: "admin",
						},
						{
							title: "KYC settings",
							page: "/admin/settings/kyc-settings",
							role: "admin",
						},
					],
				},
				{
					title: "Home",
					root: true,
					icon: "flaticon2-architecture-and-city",
					page: "/dashboard",
					translate: "MENU.DASHBOARD",
					bullet: "dot",
					role: "user",
				},
				{
					title: "My Profile",
					root: true,
					icon: "flaticon2-user",
					page: "/profile",
					role: "user",
				},
				{ section: "Components" },
				{
					title: "Payments",
					bullet: "dot",
					icon: "fa fa-money-check-alt",
					root: true,
					role: "user",
					submenu: [
						{
							title: "Make Payment",
							page: "/payment/makepayment",
							role: "user",
						},
						{
							title: "Credit Cards",
							page: "/payment/creditcard",
							role: "user",
						},
						{
							title: "Payment History",
							page: "/payment/history",
							role: "user",
						},
						{
							title: "Charges",
							page: "/payment/charges",
							role: "user",
						},
						{
							title: "Settings",
							page: "/payment/settings",
							role: "user",
						},
					],
				},
				{
					title: "Device Setup",
					root: true,
					icon: "flaticon-download-1",
					page: "/device-setup",
					role: "user",
				},
				{
					title: "My Rates",
					root: true,
					icon: "flaticon2-percentage",
					page: "/my-rates",
					role: "user",
				},

				/* {
					title: 'VoIP Essential Proxies',
					root: true,
					icon: 'flaticon-download-1',
					page: '/voipessentialproxies',
					role:'user'
				}, */
				{ section: "Components" },
				{
					title: "InBound DID",
					bullet: "dot",
					icon: "flaticon2-list",
					root: true,
					role: "user",
					badge: {
						value: "",
						type: "",
					},
					submenu: [
						{
							title: "Purchase",
							page: "/inbound/purchase",
							role: "user",
						},
						{
							title: "Manage",
							page: "/inbound/manage",
							role: "user",
						},
						{
							title: "Number Porting",
							page: "/inbound/number-porting",
							role: "user",
						},
						{
							title: "Bulk Purchase",
							page: "/inbound/bulk-did-purchase",
							role: "user",
						},
						{
							title: "Order History",
							page: "/inbound/order-history",
							role: "user",
							badge: {
								value: "",
								type: "",
							},
						},
						{
							title: "Verified CID",
							page: "/inbound/verified-cid",
							role: "user",
						},
						{
							title: "Bulk Destination Update",
							page: "/inbound/bulk-destination-update",
							role: "user",
						},
					],
				},
				{ section: "Components" },
				{
					title: "SMS",
					bullet: "dot",
					icon: "flaticon2-sms",
					root: true,
					role: "user",
					submenu: [
						{
							title: "Outbound",
							page: "/sms/sendsms",
							role: "user",
						},
						{
							title: "Settings",
							page: "/sms/inbound",
							role: "user",
						},
						{
							title: "Logs",
							page: "/sms/cdr",
							role: "user",
						},
					],
				},
				{ section: "Components" },
				{
					title: "Call Logs",
					bullet: "dot",
					icon: "flaticon-support",
					root: true,
					role: "user",
					submenu: [
						{
							title: "Call Stats",
							page: "/calllogs/dailyusagereport",
							role: "user",
						},
						{
							title: "CDR Search",
							page: "/calllogs/logs",
							role: "user",
						},
						{
							title: "CDR Download",
							page: "/calllogs/cdrdownload",
							role: "user",
						},
					],
				},
				{
					title: "Caller Reputation",
					root: true,
					icon: "flaticon-graph",
					page: "/caller-reputation",
					role: "user",
					badge: {
						value: "BETA",
						type: "",
					},
				},
				{
					title: "Subscriptions",
					root: true,
					icon: "flaticon-envelope",
					page: "/subscriptions",
					role: "user",
				},

				{ section: "Components" },
				{
					title: "Support",
					bullet: "dot",
					icon: "flaticon-questions-circular-button",
					root: true,
					role: "user",
					submenu: [
						{
							title: "Create Ticket",
							page: "/support/createsupport",
							role: "user",
						},
						// {
						// 	title: 'View Ticket',
						// 	page: '/support/createsupport',
						// 	role:'user'
						// }
					],
				},
				/*{section: 'Components'},
				{
					title: 'Google Material',
					root: true,
					bullet: 'dot',
					icon: 'flaticon2-user',
					submenu: [
						{
							title: 'Form Controls',
							bullet: 'dot',
							submenu: [
								{
									title: 'Auto Complete',
									page: '/material/form-controls/autocomplete',
									permission: 'accessToECommerceModule'
								},
								{
									title: 'Checkbox',
									page: '/material/form-controls/checkbox'
								},
								{
									title: 'Radio Button',
									page: '/material/form-controls/radiobutton'
								},
								{
									title: 'Datepicker',
									page: '/material/form-controls/datepicker'
								},
								{
									title: 'Form Field',
									page: '/material/form-controls/formfield'
								},
								{
									title: 'Input',
									page: '/material/form-controls/input'
								},
								{
									title: 'Select',
									page: '/material/form-controls/select'
								},
								{
									title: 'Slider',
									page: '/material/form-controls/slider'
								},
								{
									title: 'Slider Toggle',
									page: '/material/form-controls/slidertoggle'
								}
							]
						},
						{
							title: 'Navigation',
							bullet: 'dot',
							submenu: [
								{
									title: 'Menu',
									page: '/material/navigation/menu'
								},
								{
									title: 'Sidenav',
									page: '/material/navigation/sidenav'
								},
								{
									title: 'Toolbar',
									page: '/material/navigation/toolbar'
								}
							]
						},
						{
							title: 'Layout',
							bullet: 'dot',
							submenu: [
								{
									title: 'Card',
									page: '/material/layout/card'
								},
								{
									title: 'Divider',
									page: '/material/layout/divider'
								},
								{
									title: 'Expansion panel',
									page: '/material/layout/expansion-panel'
								},
								{
									title: 'Grid list',
									page: '/material/layout/grid-list'
								},
								{
									title: 'List',
									page: '/material/layout/list'
								},
								{
									title: 'Tabs',
									page: '/material/layout/tabs'
								},
								{
									title: 'Stepper',
									page: '/material/layout/stepper'
								},
								{
									title: 'Default Forms',
									page: '/material/layout/default-forms'
								},
								{
									title: 'Tree',
									page: '/material/layout/tree'
								}
							]
						},
						{
							title: 'Buttons & Indicators',
							bullet: 'dot',
							submenu: [
								{
									title: 'Button',
									page: '/material/buttons-and-indicators/button'
								},
								{
									title: 'Button toggle',
									page: '/material/buttons-and-indicators/button-toggle'
								},
								{
									title: 'Chips',
									page: '/material/buttons-and-indicators/chips'
								},
								{
									title: 'Icon',
									page: '/material/buttons-and-indicators/icon'
								},
								{
									title: 'Progress bar',
									page: '/material/buttons-and-indicators/progress-bar'
								},
								{
									title: 'Progress spinner',
									page: '/material/buttons-and-indicators/progress-spinner'
								},
								{
									title: 'Ripples',
									page: '/material/buttons-and-indicators/ripples'
								}
							]
						},
						{
							title: 'Popups & Modals',
							bullet: 'dot',
							submenu: [
								{
									title: 'Bottom sheet',
									page: '/material/popups-and-modals/bottom-sheet'
								},
								{
									title: 'Dialog',
									page: '/material/popups-and-modals/dialog'
								},
								{
									title: 'Snackbar',
									page: '/material/popups-and-modals/snackbar'
								},
								{
									title: 'Tooltip',
									page: '/material/popups-and-modals/tooltip'
								}
							]
						},
						{
							title: 'Data table',
							bullet: 'dot',
							submenu: [
								{
									title: 'Paginator',
									page: '/material/data-table/paginator'
								},
								{
									title: 'Sort header',
									page: '/material/data-table/sort-header'
								},
								{
									title: 'Table',
									page: '/material/data-table/table'
								}
							]
						}
					]
				},
				{
					title: 'Ng-Bootstrap',
					root: true,
					bullet: 'dot',
					icon: 'flaticon2-digital-marketing',
					submenu: [
						{
							title: 'Accordion',
							page: '/ngbootstrap/accordion'
						},
						{
							title: 'Alert',
							page: '/ngbootstrap/alert'
						},
						{
							title: 'Buttons',
							page: '/ngbootstrap/buttons'
						},
						{
							title: 'Carousel',
							page: '/ngbootstrap/carousel'
						},
						{
							title: 'Collapse',
							page: '/ngbootstrap/collapse'
						},
						{
							title: 'Datepicker',
							page: '/ngbootstrap/datepicker'
						},
						{
							title: 'Dropdown',
							page: '/ngbootstrap/dropdown'
						},
						{
							title: 'Modal',
							page: '/ngbootstrap/modal'
						},
						{
							title: 'Pagination',
							page: '/ngbootstrap/pagination'
						},
						{
							title: 'Popover',
							page: '/ngbootstrap/popover'
						},
						{
							title: 'Progressbar',
							page: '/ngbootstrap/progressbar'
						},
						{
							title: 'Rating',
							page: '/ngbootstrap/rating'
						},
						{
							title: 'Tabs',
							page: '/ngbootstrap/tabs'
						},
						{
							title: 'Timepicker',
							page: '/ngbootstrap/timepicker'
						},
						{
							title: 'Tooltips',
							page: '/ngbootstrap/tooltip'
						},
						{
							title: 'Typehead',
							page: '/ngbootstrap/typehead'
						}
					]
				},
				{section: 'Applications'},
				{
					title: 'eCommerce',
					bullet: 'dot',
					icon: 'flaticon2-list-2',
					root: true,
					permission: 'accessToECommerceModule',
					submenu: [
						{
							title: 'Customers',
							page: '/ecommerce/customers'
						},
						{
							title: 'Products',
							page: '/ecommerce/products'
						},
					]
				},
				{
					title: 'User Management',
					root: true,
					bullet: 'dot',
					icon: 'flaticon2-user-outline-symbol',
					submenu: [
						{
							title: 'Users',
							page: '/user-management/users'
						},
						{
							title: 'Roles',
							page: '/user-management/roles'
						}
					]
				},
				{section: 'Custom'},
				{
					title: 'Error Pages',
					root: true,
					bullet: 'dot',
					icon: 'flaticon2-list-2',
					submenu: [
						{
							title: 'Error 1',
							page: '/error/error-v1'
						},
						{
							title: 'Error 2',
							page: '/error/error-v2'
						},
						{
							title: 'Error 3',
							page: '/error/error-v3'
						},
						{
							title: 'Error 4',
							page: '/error/error-v4'
						},
						{
							title: 'Error 5',
							page: '/error/error-v5'
						},
						{
							title: 'Error 6',
							page: '/error/error-v6'
						},
					]
				},
				{
					title: 'Wizard',
					root: true,
					bullet: 'dot',
					icon: 'flaticon2-mail-1',
					submenu: [
						{
							title: 'Wizard 1',
							page: '/wizard/wizard-1'
						},
						{
							title: 'Wizard 2',
							page: '/wizard/wizard-2'
						},
						{
							title: 'Wizard 3',
							page: '/wizard/wizard-3'
						},
						{
							title: 'Wizard 4',
							page: '/wizard/wizard-4'
						},
					]
				},*/
			],
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
